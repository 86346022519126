// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-home-tsx": () => import("/opt/build/repo/src/templates/home.tsx" /* webpackChunkName: "component---src-templates-home-tsx" */),
  "component---src-templates-about-tsx": () => import("/opt/build/repo/src/templates/about.tsx" /* webpackChunkName: "component---src-templates-about-tsx" */),
  "component---src-templates-events-tsx": () => import("/opt/build/repo/src/templates/events.tsx" /* webpackChunkName: "component---src-templates-events-tsx" */),
  "component---src-templates-map-tsx": () => import("/opt/build/repo/src/templates/map.tsx" /* webpackChunkName: "component---src-templates-map-tsx" */),
  "component---src-templates-review-tsx": () => import("/opt/build/repo/src/templates/review.tsx" /* webpackChunkName: "component---src-templates-review-tsx" */),
  "component---src-templates-post-tsx": () => import("/opt/build/repo/src/templates/post.tsx" /* webpackChunkName: "component---src-templates-post-tsx" */)
}

